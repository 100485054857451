import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ContactUser } from '../models/contact-user';
import { ContactService } from './contact.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactForm: UntypedFormGroup;
  dataContactUser: ContactUser;
  submitted = false;

  constructor(private formBuilder: UntypedFormBuilder,
    private contactService: ContactService
    ) {
    this.contactForm = this.createContactForm();
   }

  ngOnInit(): void {
  }

  get f() {
    return this.contactForm.controls;
  }

  createContactForm(): UntypedFormGroup {
    return this.formBuilder.group({
      nombre: ['', [Validators.required]],
      email: ['', [Validators.required]],
      descripcion: ['', [Validators.required]]
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.contactForm.invalid) {
      return;
    } else {
      this.setContactUser(this.contactForm.getRawValue());
      this.contactService.saveContact(this.dataContactUser).subscribe(data => {
        Swal.fire('Enviado!', data.descripcion, 'success');
        this.cleanContactForm();
      },
      (err: HttpErrorResponse) => {
        console.log(err);
      })
    }
  }

  cleanContactForm() {
    this.contactForm.reset();
    this.submitted = false;
  }

  setContactUser(contactUser: ContactUser) {
    this.dataContactUser = contactUser;
  }
}

