<app-header></app-header>

<!-- Hero Area -->
<section class="hero-area">
  <div class="hero-slider">
    <!-- Single Slider -->
    <div class="single-slider" style="background-image:url('assets/images/slider/slider-image2.jpg')">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-12">
            <div class="slider-text">
              <div class="text-inner">
                <span class="short">¡No te quedes sin liquidez, cede tus <strong>facturas afectas, facturas exentas
                    y facturas de compra</strong> por CESIÓN DTE de forma rápida y sencilla!</span><br>
                <h2>Manten siempre los flujos de dinero necesarios de tu empresa a solo $1.000 por cada factura
                  cedida.</h2>
                <!--<div class="button">
                    <a href="about.html" class="btn icon">About Our Company<i class="fa fa-paper-plane"></i></a>
                  </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/ End Single Slider -->
    <!-- Single Slider -->
    <div class="single-slider" style="background-image:url('assets/images/slider/slider-image1.jpg')">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-12">
            <div class="slider-text align-middle">
              <div class="text-inner">
                <span class="short"><strong>Ventajas</strong> de la Cesión Electrónica</span><br>
                <h2><i class="fa fa-check"></i>Permite acceso a financiamiento y mayor liquidez.</h2><br>
                <h2><i class="fa fa-check"></i>Mejora los flujos de caja.</h2><br>
                <h2><i class="fa fa-check"></i>Ahorro en costos de notificación de la cesión del DTE.</h2><br>
                <!--<div class="button">
                    <a href="https://www.youtube.com/watch?v=FZQPhrdKjow" class="btn play video-popup mfp-fade"><i class="fa fa-play"></i>Watch Video</a>
                  </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/ End Single Slider -->

  </div>
</section>
<!--/ End Hero Area -->

<app-login></app-login>

<!-- Features -->
<section class="features section" id="servicios">
  <div class="container">
    <div class="row">

      <div class="col-lg-4 col-md-3 col-12">
        <!-- Single Feature -->
        <div class="single-feature">
          <i class="fa fa-desktop"></i>
          <h2>Extrae los documentos tributarios electrónicos desde tu ERP, software de mercado o portal mipyme.</h2>
          <p>Descarga el XML firmado enviado al S.I.I. de tus DTES a ceder.</p>
        </div>
        <!--/ End Single Feature -->
      </div>
      <div class="col-lg-4 col-md-3 col-12 gradient-color">
        <!-- Single Feature -->
        <div class="single-feature">
          <i class="fa  fa-cloud-upload"></i>
          <h2>Carga el o los XML firmados en nuestra plataforma.</h2>
          <p>Arrastra todos los XMLs a nuestra interfaz de upload/ subida de documentos tributarios electrónicos.
          </p>
        </div>
        <!--/ End Single Feature -->
      </div>
      <div class="col-lg-4 col-md-3 col-12">
        <!-- Single Feature -->
        <div class="single-feature">
          <i class="fa fa-money "></i>
          <h2>Cede o Re-Cede los DTEs de forma invidual o masivamente.</h2>
          <p>Puedes ceder con solo 1 click tus DTES o Re-Ceder los DTEs que hayas adquirido (AECs).</p>
        </div>
        <!--/ End Single Feature -->
      </div>

    </div>
  </div>
</section>
<!--/ End Features -->

<!-- Why Choose -->
<section class="why-choose section">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="why-image">
          <img src="assets/images/why-choose.jpg" width="100%">
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="why-content">
          <h2><span><b class="number">4</b> </span>Años de Experiencia</h2>
          <p>La manera más facil de ceder tus documentos tributarios electrónicos en segundos, gestiona cuando y
            cuantas facturas transferir a un Banco, Factoring, bolsa u otra entidad en solo segundos y a solo $1.000
            el documento.</p>
          <br>
          <p>Manten siempre los flujos de dinero necesarios de tu empresa, sin planes y contratos engorrosos.
            Paga solo lo que usas cuando lo necesites.</p>
          <div class="why-inner">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-12">
                <!-- Single Choose -->
                <div class="single-choose">
                  <i class="fa fa-hand-peace-o"></i>
                  <h4>Fácil de usar</h4>
                  <p>Regístrate, carga tus XMLs y cede tus facturas electrónicas con nosotros en solo minutos.</p>
                </div>
                <!--/ End Single Choose -->
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <!-- Single Choose -->
                <div class="single-choose">
                  <i class="fa fa-check"></i>
                  <h4>Sincronizados 100% con el S.I.I.</h4>
                  <p>Estado de Cesiones sincronizadas con el S.I.I. y <strong>registro público electrónico de
                      transferencia de crédito</strong> en menos de 5 minutos.</p>
                </div>
                <!--/ End Single Choose -->
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <!-- Single Choose -->
                <div class="single-choose">
                  <i class="fa fa-cubes"></i>
                  <h4>Cesiones Masivas</h4>
                  <p>Pudes ceder lotes de hasta 500 facturas en minutos.</p>
                </div>
                <!--/ End Single Choose -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--/ End Why Choose -->



<!-- Features -->
<section class="features2 section" id="monitor">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="single-feature">
          <div align="center">
            <h2><span>Monitor de Cesión Electrónica</span> para Bancos y Factoring</h2>
            <p>Características principales del Monitor de Cesión DTE</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-lg-4 col-md-3 col-12 gradient-color">
        <!-- Single Feature -->
        <div class="single-feature">
          <i class="icon back fa fa-bell fa-3x"></i>
          <h2>Alertas y Notificaciones.</h2>
          <p>Monitoreamos tus cesiones electrónicas realizadas y adquiridas de eventos tributarios tales como
            anulación, modificación de montos mediante nota de débito o nota de crédito en tiempo real.</p>
          <br>
          <p>Notificación de nuevas cesiones realizadas(re-cesión).</p>
          <br>
          <p>Notificación de nuevas cesiones adquiridas(cesión de clientes).</p>
        </div>
        <!--/ End Single Feature -->
      </div>
      <div class="col-lg-4 col-md-3 col-12 gradient-color">
        <!-- Single Feature -->
        <div class="single-feature">
          <i class="icon back fa fa-file-code-o fa-3x"></i>
          <h2>AEC en formato xml y certificado de anotación en el registro electrónico.</h2>
          <p>Obtén desde la misma plataforma el AEC (Archivo Electrónico de Cesión) y Certificado de anotación en el
            registro electrónico, ahorra tiempo sin tener que ingresar al portal del S.I.I. a buscar estos
            documentos.</p>
        </div>
        <!--/ End Single Feature -->
      </div>
      <div class="col-lg-4 col-md-3 col-12 gradient-color">
        <!-- Single Feature -->
        <div class="single-feature">
          <i class="icon back fa fa-rss fa-3x"></i>
          <h2>Actualización de Cesiones Electrónicas cada 15 minutos.</h2>
          <p>Sincronizamos todas las cesiones electrónicas realizadas o adquiridas de tu empresa directamente del
            S.I.I. cada 15 minutos, para que tengas control total de tus transacciones.</p>
        </div>
        <!--/ End Single Feature -->
      </div>

    </div>
  </div>
</section>
<!--/ End Features -->

<app-register></app-register>

<!-- CountDown -->
<section class="countdown section">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section-title">
          <h2><span>Datos</span>Cesión DTE</h2>
          <!--<p>Sed lorem enim, faucibus at erat eget, laoreet tincidunt tortor. Ut sed mi nec ligula bibendum aliquam. Sed scelerisque maximus magna, a vehicula turpis Proin</p>
          -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-12">
        <!-- Single Count -->
        <div class="single-count">
          <i class="fa fa-file-code-o"></i>
          <div class="count"><span class="counter numcifras">134,293</span></div>
          <h4>Cesiones Realizadas</h4>
        </div>
        <!--/ End Single Count -->
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <!-- Single Count -->
        <div class="single-count">
          <i class="fa fa-users"></i>
          <div class="count"><span class="counter numcifras">350</span></div>
          <h4>Usuarios Activos</h4>
        </div>
        <!--/ End Single Count -->
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <!-- Single Count -->
        <div class="single-count">
          <i class="fa fa-money"></i>
          <div class="count">$<span class="counter numcifras">1,021,530,560,429</span></div>
          <h4>Millones Cedidos</h4>
        </div>
        <!--/ End Single Count -->
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <!-- Single Count -->
        <div class="single-count">
          <i class="fa fa-industry"></i>
          <div class="count"><span class="counter numcifras">459</span></div>
          <h4>Empresas Activas</h4>
        </div>
        <!--/ End Single Count -->
      </div>
    </div>
  </div>
  <!--/ End Counter -->
</section>
<!--/ End CountDown -->

<!-- Testimonials -->
<section class="testimonials">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-12">
        <div class="testimonials-slider">
          <!-- Single Testimonial -->
          <div class="single-testimonial">
            <div class="image">
              <img src="https://v.fastcdn.co/u/a8de3c88/47748180-0-Logo-Horizontal-fond.png" alt="#">
            </div>
            <p>Gracias a CesionDTE hemos podido agilizar todo el flujo operativo en la relación con nuestros
              partners financieros, logrando transferir los documentos (cesión y re-cesión) de manera automática y
              con una plataforma muy sencilla para manejar.
              Sumado a lo anterior y muy importante por lo demás, las personas responsables de esta plataforma
              siempre muy prestas y ágiles para ayudarte y solucionar todo tipo de requerimientos.</p>
            <div class="t-info">
              <h4><a href="https://www.xepelin.com" target="_blank">Felipe Puntarelli L.<span>Co-fundador - X
                    Capital SpA (Plataforma Xepelin)</span></a></h4>
            </div>
          </div>
          <!--/ End Single Testimonial -->
          <!-- Single Testimonial -->
          <div class="single-testimonial">
            <div class="image">
              <img src="https://www.appoctava.cl/images/redcapital.png" alt="#">
            </div>
            <p>Es una empresa seria con soluciones simples pero bien ejecutadas. Un agrado trabajar con Moisés
              Osorio y Henry Molina.</p>
            <div class="t-info">
              <h4><a href="http://www.redcapital.cl" target="_blank">Felipe Zanberk<span>Gerente Comercial -
                    RedCapital SpA</span></a></h4>
            </div>
          </div>
          <!--/ End Single Testimonial -->
          <!-- Single Testimonial -->
          <div class="single-testimonial">
            <div class="image">
              <img src="https://www.appoctava.cl/images/cloux.png" alt="#">
            </div>
            <p>Me parece una web práctica, simple y útil, además de contar con un valor conveniente para los
              clientes por cada dte cedido. Creo que deberían hacer más conocida su web, ya que llegue a ella porque
              en el S.I.I tuve problema para cesiones electrónicas, donde no fueron capaces de dar respuesta en 1
              mes.
              Para los que factorizamos, es una herramienta clave.</p>
            <div class="t-info">
              <h4><a href="http://www.cloux.cl/" target="_blank">Jaime Araya Mora<span>Gerente General -
                    Cloux</span></a></h4>
            </div>
          </div>
          <!--/ End Single Testimonial -->

        </div>
      </div>
    </div>
  </div>

</section>
<!--/ End Testimonials -->



<!-- Team -->
<section class="team section">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section-title">
          <h2><span class="title-bg">Team</span>Fundadores</h2>
          <p><a href="http://www.appoctava.cl" target="_blank"><span>Octava Software SPA (Servicio Cesión DTE)</span> </a>
            es una empresa especialista en documentación electrónica.<br>
            Posee soluciones para la emisión, recepción y cesión de documentos tributarios electrónicas para
            pequeñas,
            medianas y grandes empresas desde el año 2017.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-3 col-6">
        <!-- Single Team -->
        <div class="team-main">
          <!-- Team Front -->
          <div class="single-team front">
            <div align="center">
              <img src="assets/images/moises.jpg" alt="#">
            </div>
            <div class="team-bottom">
              <h4><span>Co- Fundador</span>MOISÉS OSORIO ESPINOZA</h4>
            </div>
          </div>
          <!--/ End Team Front -->
          <!-- Team Back -->
          <div class="single-team back">
            <ul class="social">
              <li><a href="https://www.linkedin.com/in/mosorio1990/" target="_blank"><i
                    class="icofont icofont-social-linkedin"></i></a></li>
            </ul>
            <a href="https://www.appoctava.cl" target="_blank" class="btn animate">Sitio Web</a>
          </div>
          <!--/ End Team Back -->
        </div>
        <!--/ End Single Team -->
      </div>


      <div class="col-lg-6 col-md-3 col-6">
        <!-- Single Team -->
        <div class="team-main">
          <!-- Team Front -->
          <div class="single-team front">
            <div align="center">
              <img src="assets/images/henry.jpg" alt="#">
            </div>
            <div class="team-bottom">
              <h4><span>Co- Fundador</span>HENRY MOLINA GONZALEZ</h4>
            </div>
          </div>
          <!--/ End Team Front -->
          <!-- Team Back -->
          <div class="single-team back">
            <p>HENRY MOLINA GONZÁLEZ</p>
            <ul class="social">
              <li><a href="https://www.linkedin.com/in/henry-molina-gonz%C3%A1lez-6a9b999a/" target="_blank"><i
                    class="icofont icofont-social-linkedin"></i></a></li>
            </ul>
            <a href="https://www.appoctava.cl" target="_blank" class="btn animate">Sitio Web</a>
          </div>
          <!--/ End Team Back -->
        </div>
        <!--/ End Single Team -->
      </div>
    </div>
  </div>
</section>
<!--/ End Team -->

<!-- Blog Archive -->
<section class="blogs archive list section" id="integrate">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section-title">
          <h2><span class="title-bg"></span>Integra tu Software</h2>
        </div>
      </div>
    </div>
    <div class="row">

      <div class="col-12">
        <!-- Single Blog -->
        <div class="single-blog">
          <div class="blog-head">
            <img src="assets/images/blog/blog-archive5.jpg">
          </div>
          <div class="blog-bottom">
            <h4><a href="#integrate">¿Cómo me integro?</a></h4>
            <p>Si tu software o lenguaje de programación pueden consumir un web service, te entregamos ejemplos para
              su fácil integración:</p><br>
            <a target="_blank" href="http://www.appoctava.cl/wscesion/documentacion-cesion-electronica-api-rest/">Link
              documentación
              Api Rest</a><br>
            <a target="_blank" href="http://www.appoctava.cl/wscesion/documentacion-cesion-electronica-php/">Link
              documentación Ws Soap</a>
          </div>
        </div>
        <!--/ End Single Blog -->
      </div>

    </div>

  </div>
</section>
<!--/ End Blog Archive -->


<!-- Breadcrumbs -->
<section class="breadcrumbs overlay bg-image" id="contacto">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- Bread Title -->
        <div class="bread-title">
          <h2><span>Para más información </span>Contáctanos</h2>
        </div>

      </div>
    </div>
  </div>
</section>
<!--/ End Breadcrumbs -->
<app-contact></app-contact>

<app-footer></app-footer>