<!-- Contact Us -->
<section id="contact-us" class="contact-us section">
    <div class="container">
        <div class="row">
            <!-- Contact Form -->
            <div class="col-lg-6 col-12">
                <div class="form-main">
                    <p>Déjanos tus Preguntas</p>
                    <!-- Form -->
                    <form class="form" [formGroup]="contactForm">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Nombre<span>*</span></label>
                                    <input type="text" formControlName="nombre" name="nombrecontacto" id="nombrecontacto" required="required">
                                    <div *ngIf="contactForm.get('nombre')?.errors?.['required'] && submitted" class="alert alert-danger mb-3 mt-0">Nombre es requerido</div>    
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Email<span>*</span></label>
                                    <input type="email" formControlName="email" name="emailcontacto" id="emailcontacto" required="required">
                                    <div *ngIf="contactForm.get('email')?.errors?.['required'] && submitted" class="alert alert-danger mb-3 mt-0">Email es requerido</div>    
                                </div>
                            </div>

                            <div class="col-lg-12 col-12">
                                <div class="form-group">
                                    <label>Cuéntanos tu inquietud<span>*</span></label>
                                    <textarea name="mensajecontacto" formControlName="descripcion" id="mensajecontacto" rows="6"></textarea>
                                    <div *ngIf="contactForm.get('descripcion')?.errors?.['required'] && submitted" class="alert alert-danger mb-3 mt-0">Descripción es requerido</div>    
                                </div>
                            </div>
                            <div class="col-lg-12 col-12">
                                <div class="form-group button">
                                    <button type="button" class="btn primary animate"
                                    [type]="onSubmit" (click)="onSubmit()">Enviar</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <!--/ End Form -->
                </div>
            </div>
            <!--/ End Contact Form -->
            <!-- Contact Address -->
            <div class="col-lg-6 col-12">
                <div class="contact-address">
                    <div class="contact">
                        <h4>Contáctanos</h4>
                        <p>Déjanos todas tus dudas o consultas y te responderemos a la brevedad.</p>
                        <!-- Single Address -->
                        <div class="single-address">
                            <span><i class="icofont icofont-phone"></i>Teléfonos</span>
                            <p>(+ 56) 9 79874947 | (+ 56) 9 90971818</p>
                        </div>
                        <!--/ End Single Address -->
                        <!-- Single Address -->
                        <div class="single-address">
                            <span><i class="icofont icofont-envelope-open"></i>Email</span>
                            <p><a href="mailto:mosorio@appoctava.cl">mosorio@appoctava.cl</a>, <a
                                    href="mailto:hmolina@appoctava.cl">hmolina@appoctava.cl</a></p>
                        </div>
                        <!--/ End Single Address -->
                        <!-- Single Address -->

                    </div>


                </div>
            </div>
            <!--/ End Contact Address -->
        </div>
    </div>
</section>
<!--/ End Contact Us -->