import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ContactUser } from '../models/contact-user';
import { ContactUserResponse } from '../models/contact-user-response';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private readonly API_URL_CONTACT = environment.apiUrl + '/contact';

  constructor(private httpClient: HttpClient) { }

  public saveContact(contactUser: ContactUser): Observable<ContactUserResponse> {
    return this.httpClient.post<ContactUserResponse>(this.API_URL_CONTACT, contactUser);
  }
}
