<section class="newsletter overlay">
    <div class="container">
        <div class="row">

            <div class="col-lg-12 col-6">
                <form class="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    
                    <div class="form-group">
                        <i class="fa fa-user"></i>
                        <input name="rut" id="rut" formControlName="rut" placeholder="RUT" required="required" type="text" maxlength="13">
                        <div *ngIf="loginForm.get('rut')?.errors?.['required'] && submitted" class="alert alert-danger mb-3 mt-0">Rut es requerido</div>
                        <div *ngIf= "loginForm.get('rut')?.errors?.['rutnovalido']" class="alert alert-danger mb-3 mt-0">El rut no es válido</div>
                        <!--input type="text" id="firstName" placeholder="Put your first name" pattern="[a-zA-Z]{2,20}" required /-->

                    </div>
                    <div class="form-group">
                        <i class="fa fa-commenting-o"></i>
                        <input name="password" id="password" formControlName="password" placeholder="PASSWORD" required="required" type="password">
                        <div *ngIf="loginForm.get('password')?.errors?.['required'] && submitted" class="alert alert-danger mb-3 mt-0">Password es requerido{{errorPass}}</div>
                    </div>
                    <div class="form-group">
                        <i class="fa fa-external-link-square"></i>
                        <button [type]="onSubmit" class="button primary"><i
                                class="fa fa-paper-plane"></i>Ingresar</button>    
                    </div>
                    <div class="form-group">
                        <i class="fa fa-registered"></i>
                        <a href="#registrate"><button type="button"><i
                                    class="fa fa-registered"></i>Regístrar</button></a>
                    </div>



                </form>
            </div>
        </div>
    </div>
</section>