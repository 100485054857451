import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { CookieService } from 'ngx-cookie';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdminUserModel } from '../models/admin-use-account';
import { RutService } from 'rut-chileno';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;
  dataUser: AdminUserModel;
  errorRut = '';
  errorPass = '';
  submitted = false;
  dataInfoUser: string[] = [];
  channel: string = 'logincesiondte';

  //private readonly API_URL_CESION_DTE = environment.apiUrl2 +"/main/#/dashboard/main";
  //private readonly API_URL_CESION_DTE = "http://localhost:4201/#/dashboard/main";

  constructor(private loginService: LoginService,
    @Inject(DOCUMENT) private document: Document,
    public cookieService: CookieService,
    private formBuilder: UntypedFormBuilder,
    private rutService: RutService
  ) {
    this.loginForm = this.createLoginForm();
    // Escucha los cambios en el campo del formulario
    this.loginForm.get('rut').valueChanges.subscribe((newValue) => {
      // Aplica el formato al RUT y actualiza el valor en el formulario
      this.loginForm.get('rut').setValue(this.formatRut(newValue), { emitEvent: false });
    });
  }

  ngOnInit(): void {
  }

  createLoginForm(): UntypedFormGroup {
    return this.formBuilder.group({
      rut: ['', [Validators.required, this.rutService.validaRutForm]],
      password: ['', [Validators.required]]
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.errorRut = '';
    this.errorPass = '';
   
    if (this.loginForm.invalid) {
      return;
    } else {

      this.setAdminUserModel(this.loginForm.getRawValue());

      const rutFormated = this.formatearRut(this.dataUser.rut);

      this.loginService.login(rutFormated, this.dataUser.password, this.channel).subscribe(data => {
        if (data.token !== null && data.role !== null) {
          this.document.location.href = environment.apiUrlMain + '/' + '?user=' + rutFormated;
        } else {
          this.showTitleErorIcon();
        }
      });
    }
  }

  formatearRut(rut: string): string {
    // Verificar si el RUT está vacío
    if (!rut || rut.trim() === '') {
      return ''; // Devolver cadena vacía si el RUT es nulo o vacío
    }

    // Eliminar puntos del RUT
    const rutSinPuntos = rut.replace(/\./g, '');

    // Verificar si el RUT ya tiene guion
    if (rutSinPuntos.includes('-')) {
      return rutSinPuntos; // Devolver el RUT sin modificar si ya tiene guion
    }

    // Separar el dígito verificador del resto del RUT
    const parteNumerica = rutSinPuntos.slice(0, -1);
    const digitoVerificador = rutSinPuntos.slice(-1);

    // Agregar guion al RUT
    const rutFormateado = `${parteNumerica}-${digitoVerificador}`;

    return rutFormateado;
  }

  // Función para dar formato al RUT
  formatRut(rut: string): string {
    rut = rut.replace(/[^\dKk]/g, ''); // Remover caracteres no numéricos y 'K'
    if (rut.length <= 1) {
      return rut; // Si es muy corto, devolverlo sin cambios
    }
    
    const rutDigits = rut.slice(0, -1); // Tomar todos los dígitos excepto el último
    const verifierDigit = rut.slice(-1).toUpperCase(); // Tomar el último dígito (puede ser 'k' o 'K')
  
    // Usar la función `toLocaleString` para formatear con puntos como separadores de miles
    const formattedRutDigits = parseInt(rutDigits, 10).toLocaleString('es-CL');
  
    // Combinar los dígitos formateados con el dígito verificador
    return `${formattedRutDigits}-${verifierDigit}`;
  }

  setAdminUserModel(adminUserModel: AdminUserModel) {
    this.dataUser = adminUserModel;
  }

  showTitleErorIcon() {
    Swal.fire({
      icon: 'error',
      title: 'Mensaje de Error',
      text: 'Credenciales de ingreso incorrectas, intentelo otra vez!'/*,
      footer: '<a href>Why do I have this issue?</a>'*/
    });
  }
}
