<!-- Consulting -->
<section id="registrate"
  class="consulting overlay dzsparallaxer auto-init height-is-based-on-content use-loading mode-scroll out-of-bootstrap"
  data-options='{ direction: "normal"}'>
  <div class="divimage dzsparallaxer--target bg-image"
    style="width: 100%; height: 150%; background-image: url(assets/images/call-bg.jpg)"></div>
  <div class="consult-main">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="form-area">
            <h2>Registrate</h2>
            <p>Registra tus datos para disfrutar de nuestro Software</p>
            <!-- Consult Form -->
            <form class="form" [formGroup]="registerForm">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="form-group">
                    <i class="fa fa-user-circle-o"></i>
                    <input name="rutusuario" formControlName="rutUsuario" id="rutusuario" type="text"
                      placeholder="RUT USUARIO Ej.:11111111-1" required="required">
                      <div *ngIf="registerForm.get('rutUsuario')?.errors?.['required'] && submitted" class="alert alert-danger mb-3 mt-0">Rut Usuario es requerido</div>
                      <div *ngIf= "registerForm.get('rutUsuario')?.errors?.['rutnovalido']" class="alert alert-danger mb-3 mt-0">El rut no es válido</div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="form-group">
                    <i class="fa fa-user-circle-o"></i>
                    <input name="nombreusuario" formControlName="nombre" id="nombreusuario" type="text" placeholder="NOMBRE USUARIO"
                      required="required">
                      <div *ngIf="registerForm.get('nombre')?.errors?.['required'] && submitted" class="alert alert-danger mb-3 mt-0">Nombre Usuario es requerido</div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="form-group">
                    <i class="fa fa-user-circle-o"></i>
                    <input name="apellidopaterno" formControlName="apellidoPaterno" id="apellidopaterno" type="text" placeholder="APELLIDO PATERNO"
                      required="required">
                      <div *ngIf="registerForm.get('apellidoPaterno')?.errors?.['required'] && submitted" class="alert alert-danger mb-3 mt-0">Apellido Paterno es requerido</div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="form-group">
                    <i class="fa fa-user-circle-o"></i>
                    <input name="apellidomaterno" formControlName="apellidoMaterno" id="apellidomaterno" type="text" placeholder="APELLIDO MATERNO"
                      required="required">
                      <div *ngIf="registerForm.get('apellidoMaterno')?.errors?.['required'] && submitted" class="alert alert-danger mb-3 mt-0">Apellido Materno es requerido</div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-12">
                  <div class="form-group">
                    <i class="fa fa-envelope-open-o"></i>
                    <input name="email" id="email" formControlName="email" type="email" placeholder="EMAIL" required="required">
                    <div *ngIf="registerForm.get('email')?.errors?.['required'] && submitted" class="alert alert-danger mb-3 mt-0">Email es requerido</div>
                  </div>
                </div>


                <div class="col-12">
                  <div class="form-group button">
                    <button type="submit" [type]="onSubmit" (click)="onSubmit()" class="btn animate">Registrar</button>
                  </div>
                </div>
              </div>
            </form>
            <!--/ End Consult Form -->
          </div>
        </div>
      </div>
    </div>
    <!-- Consult Right -->
  </div>
</section>
<!--/ End Consulting -->
