import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RutService } from 'rut-chileno';
import Swal from 'sweetalert2';
import { RegisterUser } from '../models/register-user';
import { RegisterService } from './register.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: UntypedFormGroup;
  dataRegisterUser: RegisterUser;
  submitted = false;

  constructor(private formBuilder: UntypedFormBuilder,
    private registerService: RegisterService,
    private rutService: RutService) { 
    this.registerForm = this.createRegisterForm();
    // Escucha los cambios en el campo del formulario
    this.registerForm.get('rutUsuario').valueChanges.subscribe((newValue) => {
      // Aplica el formato al RUT y actualiza el valor en el formulario
      this.registerForm.get('rutUsuario').setValue(this.formatRut(newValue), { emitEvent: false });
    });
  }

  ngOnInit(): void {
  }

  get f() {
    return this.registerForm.controls;
  }

  createRegisterForm(): UntypedFormGroup {
    return this.formBuilder.group({
      rutUsuario: ['', [Validators.required, this.rutService.validaRutForm]],
      nombre: ['', [Validators.required]],
      apellidoPaterno: ['', [Validators.required]],
      apellidoMaterno: ['', [Validators.required]],
      email: ['', [Validators.required]]
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    } else {
      this.setRegisterUser(this.registerForm.getRawValue());
      this.registerService.saveRegister(this.dataRegisterUser).subscribe(data => {
        Swal.fire('Información!', data.descripcion, 'success');
        this.cleanRegisterForm();
      },
      (err: HttpErrorResponse) => {
        console.log(err);
      })
    }
  }

  cleanRegisterForm() {
    this.registerForm.reset();
    this.submitted = false;
  }

  setRegisterUser(registerUser: RegisterUser) {
    this.dataRegisterUser = registerUser;
  }

  // Función para dar formato al RUT
  formatRut(rut: string): string {
    rut = rut.replace(/[^\dKk]/g, ''); // Remover caracteres no numéricos y 'K'
    if (rut.length <= 1) {
      return rut; // Si es muy corto, devolverlo sin cambios
    }
    
    const rutDigits = rut.slice(0, -1); // Tomar todos los dígitos excepto el último
    const verifierDigit = rut.slice(-1).toUpperCase(); // Tomar el último dígito (puede ser 'k' o 'K')
  
    // Usar la función `toLocaleString` para formatear con puntos como separadores de miles
    const formattedRutDigits = parseInt(rutDigits, 10).toLocaleString();
  
    // Combinar los dígitos formateados con el dígito verificador
    return `${formattedRutDigits}-${verifierDigit}`;
  }
}
