export const environment = {
  production: true,

  //url: https://new.cesiondte.cl
  //apiUrl: 'https://new.cesiondte.cl/ms-cesiondte-0.0.1-SNAPSHOT',
  //apiUrl2: 'https://new.cesiondte.cl',
  //apiUrlMain: 'https://new.cesiondte.cl/main/#/dashboard/main'

  //url: https://www.cesiondte.cl
  apiUrl: 'https://www.cesiondte.cl/ms-cesiondte-0.0.1-SNAPSHOT',
  apiUrl2: 'https://www.cesiondte.cl',
  apiUrlMain: 'https://www.cesiondte.cl/main/#/dashboard/main'
};
