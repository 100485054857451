<!-- Footer -->
<footer class="footer">
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-3 col-3">
                    <!-- About Widget -->
                    <div class="single-widget about">
                        <img src="assets/images/logo1.png" alt="#">
                        <p>La manera más facil de ceder tus documentos tributarios electrónicos en segundos, gestiona
                            cuando y
                            cuantas facturas transferir a un Banco, Factoring, bolsa u otra entidad en solo segundos y a
                            solo
                            $1.000 el documento.</p>
                        <ul class="social">
                            <li><a href="https://www.facebook.com/OctavaSoftware" target="_blank"><i
                                        class="icofont icofont-social-facebook"></i></a></li>
                            <!--<li><a href="#"><i class="icofont icofont-social-linkedin"></i></a></li>
                <li><a href="#"><i class="icofont icofont-social-youtube"></i></a></li>-->
                        </ul>
                    </div>
                    <!--/ End About Widget -->
                </div>

                <div class="col-lg-4 col-md-3 col-3">
                    <!-- Posts Widget -->
                    <div class="single-widget posts">
                        <h2>También Visita</h2>
                        <ul>
                            <li><img src="https://www.appoctava.cl/websites/assets/images/logo/logo.png" alt="#"><a
                                    href="https://www.appoctava.cl/" target="_blank">Solución más completa de factura
                                    electrónica de
                                    Chile</a></li>
                            <li><img src="https://www.appoctava.cl/wssms/img/1.png" alt="#"><a
                                    href="https://www.appoctava.cl/wssms/" target="_blank">La manera más fácil de enviar
                                    SMS
                                    promocionales o transaccionales desde tu software a tan solo $35 a todo Chile.</a>
                            </li>
                            <li><img src="https://www.appoctava.cl/apprecepciondte/img/1.png" alt="#"><a
                                    href="https://www.appoctava.cl/apprecepciondte/" target="_blank">La manera más fácil
                                    de llevar el
                                    control de tus documentos tributarios electrónicos recibidos.</a></li>
                        </ul>
                    </div>
                    <!--/ End Posts Widget -->
                </div>
                <div class="col-lg-4 col-md-3 col-3">
                    <!-- Address Widget -->
                    <div class="single-widget address">
                        <h2>Contacto</h2>
                        <p></p>
                        <ul class="list">
                            <li><i class="icofont icofont-phone"></i>(+ 56) 9 79874947 | (+ 56) 9 90971818</li>
                            <li><i class="icofont icofont-ui-email"></i><a
                                    href="mailto:ventas@appoctava.cl">ventas@appoctava.cl</a></li>
                            <li><i class="icofont icofont-location-arrow"></i>San Pedro de la Paz - Concepción</li>
                        </ul>
                    </div>
                    <!--/ End Address Widget -->
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Copyright -->
                    <div class="copyright">
                        <p>&copy; 2020 <a href="http://www.appoctava.cl" target="_blank">Octava Software SpA.</a></p>

                    </div>
                    <!--/ End Copyright -->
                </div>
            </div>
        </div>
    </div>
</footer>
<!--/ End footer -->