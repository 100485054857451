import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { UserInfo } from '../models/user-info';
import { UserRoleModule } from '../models/user-role-module';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser') || '{}')
    );
    this.currentUser = this.currentUserSubject.asObservable();
   }

   public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /*login(rut: any): Observable<UserInfo> {
    return this.http.get<UserInfo>(`http://localhost:8080/login/information` + "?rut=" + rut);
  }*/

  login(user: string, password: string, channel: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/login`, {
        user,
        password,
        channel
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          //this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  /*public findRoleUserByRut(rut: any): Observable<UserRoleModule[]> {
    return this.http.get<UserRoleModule[]>("http://3.228.218.202/ms-cesiondte-0.0.1-SNAPSHOT/user/role/"+rut);
  }*/

}
