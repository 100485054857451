import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './core/guard/auth.guard';

//const routes: Routes = [];

const routes: Routes = [
  {
    path: 'logincesiondte',
    component: AppComponent/*,
    canActivate: [AuthGuard]*/
  }/*,
  { path: '**', component: AppComponent }*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
