<!-- Header -->
<header class="header">
    <!-- Topbar -->
    <div class="topbar">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 col-12">
                    <!-- Top Contact -->
                    <div class="top-contact">

                        <div class="single-contact">
                            <p><i class="icofont icofont-phone"></i>(+ 56) 9 79874947 | (+ 56) 9 90971818</p>
                        </div>
                        <div class="single-contact">
                            <p><a href="mailto:ventas@appoctava.cl"><i
                                        class="icofont icofont-ui-email"></i>ventas@appoctava.cl</a></p>
                        </div>
                    </div>
                    <!--/ End Top contact -->
                </div>
                <div class="col-lg-5 col-12">
                    <div class="top-right">

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Topbar -->
    <!-- Header Inner -->
    <div class="header-inner">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-2 col-12">
                    <!-- Logo -->
                    <div class="logo">
                        <a href="index.html"><img src="assets/images/logo1.png" alt="logo"></a>
                    </div>
                    <!--/ End Logo -->
                    <div class="mobile-nav"></div>
                </div>
                <div class="col-lg-9 col-md-10 col-12">
                    <div class="menu-area">
                        <!-- Main Menu -->
                        <nav class="navbar navbar-expand-lg">
                            <div class="navbar-collapse">
                                <div class="nav-inner">
                                    <ul class="nav main-menu navbar-nav">
                                        <li><a href="index.html">Inicio<i class="fa"></i></a></li>
                                        <li><a href="#servicios">Servicios</a></li>
                                        <li><a href="#monitor">Monitor</a></li>
                                        <li><a href="#registrate">Regístrate</a></li>
                                        <li><a href="#integrate">Integra tu Software</a></li>
                                        <li><a href="#contacto">Contacto</a></li>
                                    </ul>

                                </div>
                            </div>
                        </nav>
                        <!--/ End Main Menu -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--/ End Header Inner -->
    <!-- Sidebar Area -->
    <div class="side-area">
        <div class="cross">
            <a class="btn"><i class="fa fa-remove"></i></a>
        </div>
        <!-- Logo -->
        <div class="logo">
            <a href="index-2.html"><img src="assets/images/logo.png" alt="Logo"></a>
        </div>
        <!--/ End Logo -->
        <!-- Menu -->
        <ul class="nav navbar-nav">
            <li><a href="about.html">About Us</a></li>
            <li><a href="services.html">Our Services</a></li>
            <li><a href="portfolio.html">Our Portfolio</a></li>
            <li><a href="https://www.themelamp.com/templates/trendbiz/shop.html">Our Product</a></li>
            <li><a href="blog.html">Latest News</a></li>
            <li><a href="contact.html">Contact</a></li>
        </ul>
        <!--/ End Menu -->
        <!-- Side Bottom -->
        <div class="side-bottom">
            <ul class="social">
                <li><a href="#"><i class="icofont icofont-social-facebook"></i></a></li>
                <li><a href="#"><i class="icofont icofont-social-twitter"></i></a></li>
                <li><a href="#"><i class="icofont icofont-social-linkedin"></i></a></li>
                <li><a href="#"><i class="icofont icofont-social-youtube"></i></a></li>
                <li><a href="#"><i class="icofont icofont-social-dribbble"></i></a></li>
            </ul>
            <p class="copyright">©2018 <a href="#">TrendBiz</a></p>
        </div>
        <!-- End Side Bottom -->
    </div>
    <!--/ End Sidebar Area -->
</header>
<!--/ End Header -->