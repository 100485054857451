import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { AuthGuard } from './core/guard/auth.guard';
import { JwtInterceptor } from './core/interceptor/jwt.interceptor';
import { LoginComponent } from './login/login.component';
import { CookieModule, CookieService } from 'ngx-cookie';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { RegisterComponent } from './register/register.component';
import { ContactComponent } from './contact/contact.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { RutModule } from 'rut-chileno';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ContactComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    CookieModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RutModule
  ],
  providers: [ UntypedFormBuilder, /*AuthGuard ,*/ CookieService ,{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
