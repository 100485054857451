import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'logincesiondte';

  constructor(public cookieService: CookieService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  ngOnInit(): void {
    window.history.pushState(null, null, window.location.href);
    this.logout();
    if(this.cookieService.get("logout") !== undefined) {
      this.showTitleErorIcon();
      this.cookieService.remove("logout");
    }
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    sessionStorage.removeItem('rutEmpresa');
    this.cookieService.remove("sessionId");
  }

  showTitleErorIcon() {
    Swal.fire({
      icon: 'error',
      title: 'Mensaje de Error',
      text: 'Credenciales de ingreso incorrectas, intentelo otra vez!'/*,
      footer: '<a href>Why do I have this issue?</a>'*/
    });
  }
}
