import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ContactUserResponse } from '../models/contact-user-response';
import { RegisterUser } from '../models/register-user';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  private readonly API_URL_REGISTER = environment.apiUrl + '/user/register';

  constructor(private httpClient: HttpClient) { }

  public saveRegister(registerUser: RegisterUser): Observable<ContactUserResponse> {
    return this.httpClient.post<ContactUserResponse>(this.API_URL_REGISTER, registerUser);
  }
}
